export async function useScrollElementToView(elementId: string) {
  const element = document.getElementById(elementId);
  if (element) {
    await nextTick();
    let calculatedTop =
      element.getBoundingClientRect().top -
      document.body.getBoundingClientRect().top;
    const headerElement = document.getElementById("headerElement");
    if (headerElement) {
      calculatedTop -= headerElement.getBoundingClientRect().height + 20;
    }
    window.scrollTo({
      // behavior: 'smooth',
      top: calculatedTop,
    });
  }
}

export function showMaintenanceView(error = null) {
  const statusCode = error ? error?.response?.status : 500;
  const statusMessage = error
    ? error?.response?.statusText
    : "An error occurred";

  if (Number.isInteger(statusCode) && ![401, 404].includes(statusCode)) {
    if (import.meta.client) {
      const nuxtError = useError();
      nuxtError.value = {
        statusCode,
        statusMessage,
      };
    } else {
      throw createError({
        statusCode,
        statusMessage,
      });
    }
  }
}
